<template>
	<safeguard-table-with-filter
		report-link="reports/location-visits"
		:table-headers="[
			{ key: 'project_id' },
			{ key: 'project_name' },
			{ key: 'gov' },
			{ key: 'visit_by_who' },
			{ key: 'visit_type' },
			{ key: 'date_of_visit', label: 'Date of visit' },
			{ key: 'report_submitted' },
			{ key: 'visit_purpose' },
		]"
	/>
</template>

<script>
import SafeguardTableWithFilter from '../components/SafeguardTableWithFilter.vue';

export default {
	name: 'LocationVisits',
	components: { SafeguardTableWithFilter },
};
</script>
