var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('safeguard-table-with-filter',{attrs:{"report-link":"reports/location-visits","table-headers":[
		{ key: 'project_id' },
		{ key: 'project_name' },
		{ key: 'gov' },
		{ key: 'visit_by_who' },
		{ key: 'visit_type' },
		{ key: 'date_of_visit', label: 'Date of visit' },
		{ key: 'report_submitted' },
		{ key: 'visit_purpose' } ]}})}
var staticRenderFns = []

export { render, staticRenderFns }